type FormActionType<T> = {
    type: string;
    payload?: T;
};

// actions
export const SET_FORM_STATE = "[FORM] - Set Form State";

export const SET_IS_FORM_VALID_STATE = "[FORM] - Set Is Form Valid State";

export const SET_IS_LOADING_STATE = "[FORM] - Set Is Loading State";

export const SET_IS_ERROR_STATE = "[FORM] - Set Is Error State";

export const SET_ERROR = "[FORM] - Set Error message";

export const formReducer = <T extends {}>(
    state: T,
    action: FormActionType<T>
) => {
    switch (action.type) {
        case SET_FORM_STATE:
            return {
                ...state,
                ...action.payload,
            };

        case SET_IS_LOADING_STATE:
            return {
                ...state,
                isLoading: action.payload,
            };

        case SET_IS_ERROR_STATE:
            return {
                ...state,
                isError: action.payload,
            };

        case SET_ERROR:
            return {
                ...state,
                error: action.payload,
            };

        case SET_IS_FORM_VALID_STATE:
            return {
                ...state,
                isSaveDraft: action.payload,
            };

        default:
            return state;
    }
};
