import { Cleaned } from "@interfaces/common";

/* eslint-disable @typescript-eslint/no-unused-vars */
export const cleanObject = (object: Object) => {
    return Object.fromEntries(
        Object.entries(object).filter(
            ([_, v]) => ![undefined, null, ""].includes(v)
        )
    );
};

export const cleanNestedObject = <T extends object>(obj: T): Cleaned<T> => {
    const cleanedObj: any = {};

    Object.entries(obj).forEach(([key, value]) => {
        if (value !== null && value !== undefined && value !== "") {
            if (typeof value === "object" && !Array.isArray(value)) {
                cleanedObj[key] = cleanNestedObject(value);
            } else {
                cleanedObj[key] = value;
            }
        }
    });

    return cleanedObj as Cleaned<T>;
};

export const isObjectEmpty = (object: Object) => {
    if (!object) return true;
    return Object.keys(object).length === 0 && object.constructor === Object;
};
