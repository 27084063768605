import { TENANT_THEME } from "@constants/common";
import type { ServerBootstrap } from "@interfaces/common";
import { getBootstrapByTenant } from "@lib/api/theming";
import { useQuery } from "@tanstack/react-query";
import { useRouter } from "next/router";
import { useCallback, useMemo } from "react";

export const useBootstrap = () => {
    const router = useRouter();

    const setLocale = useCallback(
        (locale: string) => {
            if (!locale) return;

            if (router.locale !== locale) {
                router.push(router.pathname, router.asPath, { locale });
            }
        },
        [router]
    );

    const isTenant = useMemo(() => !!router.query?.site, [router.query?.site]);

    const query = useQuery<Omit<ServerBootstrap, "user">>(
        [TENANT_THEME],
        () => getBootstrapByTenant(),
        {
            refetchOnWindowFocus: false,
            enabled: isTenant,
            onSuccess: data => {
                setLocale(data?.locale);
            },
        }
    );

    return { ...query, isReady: router?.isReady };
};
