import { LoadingScreen } from "@components/LoadingScreen";
import { TenantThemeContext } from "./TenantThemeContext";
import { tenantThemeReducer } from "./tenantThemeReducer";
import { useBootstrap } from "@hooks/Bootstrap/useBootstrap";
import { useReducer } from "react";

export interface TenantThemeState {
    id: string;
    name: string;
    logo: string;
    authImage?: string;
}

const THEME_INITIAL_STATE: TenantThemeState = {
    id: "1",
    name: "",
    logo: "",
    authImage: "",
};

export const TenantThemeProvider = ({ children }) => {
    const [state, dispatch] = useReducer(
        tenantThemeReducer,
        THEME_INITIAL_STATE
    );

    const { isFetching, isReady, data } = useBootstrap();

    const setTenantTheme = (tenant: string) => {
        dispatch({ type: `[THEME] - Set Tenant Theme`, payload: tenant });
    };

    return (
        <TenantThemeContext.Provider
            value={{
                ...state,
                ...data,
                // Methods
                setTenantTheme,
            }}
        >
            {isFetching || !isReady ? <LoadingScreen /> : children}
        </TenantThemeContext.Provider>
    );
};
