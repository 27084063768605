import { useReducer } from "react";
import { FormContext } from "./FormContext";
import {
    formReducer,
    SET_ERROR,
    SET_FORM_STATE,
    SET_IS_ERROR_STATE,
    SET_IS_FORM_VALID_STATE,
    SET_IS_LOADING_STATE,
} from "./formReducer";

const FORM_INITIAL_STATE = {};

export const FormProvider = ({ children }) => {
    const [state, dispatch] = useReducer(formReducer, FORM_INITIAL_STATE);

    const setFormValues = (form: Object) => {
        dispatch({ type: SET_FORM_STATE, payload: form });
    };

    const setIsLoading = (isLoading: boolean) => {
        dispatch({ type: SET_IS_LOADING_STATE, payload: isLoading });
    };

    const setIsError = (isError: boolean) => {
        dispatch({ type: SET_IS_ERROR_STATE, payload: isError });
    };

    const setError = (error: string) => {
        dispatch({ type: SET_ERROR, payload: error });
    };

    const setIsSaveDraft = (isSaveDraft: boolean) => {
        dispatch({ type: SET_IS_FORM_VALID_STATE, payload: isSaveDraft });
    };

    return (
        <FormContext.Provider
            value={{
                ...state,
                setFormValues,
                setIsLoading,
                setIsError,
                setError,
                setIsSaveDraft,
            }}
        >
            {children}
        </FormContext.Provider>
    );
};
